module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8PWC22S6MJ","UA-108114293-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mindset Family Therapy","short_name":"Mindset Family Therapy","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","theme_color_in_head":false,"display":"standalone","icon":"src/favicon/mindset_favicon.png","include_favicon":true,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"0393d4694e259d14d6dee15db64abf0d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
